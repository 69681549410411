import React from 'react';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { classes } from './ManageButton.st.css';
import {
  ActionButtonUiTypes,
  ActionMenuConfiguration,
  ActionsMenuType,
  BookingsGroup,
  OnActionClickType,
  RescheduleButtonOrigin,
} from '../../../../types';
import {
  isBookAnotherAble,
  isRescheduleAble,
  isCancelAble,
} from '../../../../utils/bookInfo';
import { BookAgain } from '../ActionsButtons/BookAgain';
import { Reschedule } from '../ActionsButtons/Reschedule';
import { Cancel } from '../ActionsButtons/Cancel';
import { ActionsMenuDataHooks } from '../datahooks';

export const getMenuActionsConfiguration = (
  group: BookingsGroup,
  onActionClick: OnActionClickType,
): ActionMenuConfiguration[] | null => {
  const upperActions: ActionMenuConfiguration[] = [];
  const bottomActions: ActionMenuConfiguration[] = [];

  if (isBookAnotherAble(group)) {
    upperActions.push({
      type: ActionsMenuType.BOOK_AGAIN,
      Component: (
        <BookAgain
          group={group}
          type={ActionButtonUiTypes.MENU}
          onActionClick={onActionClick}
        />
      ),
    });
  }

  if (isRescheduleAble(group)) {
    upperActions.push({
      type: ActionsMenuType.RESCHEDULE,
      Component: (
        <Reschedule
          origin={RescheduleButtonOrigin.ActionMenu}
          group={group}
          type={ActionButtonUiTypes.MENU}
          onActionClick={onActionClick}
        />
      ),
    });
  }

  if (isCancelAble(group)) {
    if (upperActions.length) {
      bottomActions.push({
        type: ActionsMenuType.DIVIDER,
        Component: (
          <ActionsMenuLayout
            data-hook={ActionsMenuDataHooks.ManageActionMenuDivider}
            className={classes.actionMenuLayout}
          >
            <ActionsMenuLayout.Divider />
          </ActionsMenuLayout>
        ),
      });
    }

    bottomActions.push({
      type: ActionsMenuType.CANCEL,
      Component: (
        <Cancel
          group={group}
          type={ActionButtonUiTypes.MENU}
          onActionClick={onActionClick}
        />
      ),
    });
  }

  if (!upperActions.length && !bottomActions.length) {
    return null;
  }

  return [...upperActions, ...bottomActions];
};
