import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  BookingDTO,
} from '../../../../../types';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import {
  ActionsButtonsDataHooks,
  ViewMoreDetailsDataHooks,
} from '../../datahooks';
import { classes } from './ViewMoreDetails.st.css';
import Text, { TextType } from '../../../Components/Text';

export interface ViewMoreDetailsProps extends ActionButtonProps {
  booking: BookingDTO;
}

export const ViewMoreDetails: React.FC<ViewMoreDetailsProps> = ({
  booking,
  type,
}) => {
  const { actions } = useApplicationContext();
  const { t } = useTranslation();
  let content;

  const servicePageUrl = booking.servicePageUrl;

  if (type === ActionButtonUiTypes.LINK) {
    content = (
      <a
        href={servicePageUrl}
        data-hook={ActionsButtonsDataHooks.ViewMoreDetailsLink}
        className={classes.root}
        onClick={() => {
          actions.clickOnViewMoreDetails({ booking });
        }}
      >
        <Text type={TextType.Primary} className={classes.text}>
          {t('app.my-bookings-widget.view-service-details.link.label')}
        </Text>
      </a>
    );
  }

  return <div data-hook={ViewMoreDetailsDataHooks.Main}>{content}</div>;
};
