import React, { FC, useEffect, useState } from 'react';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import { BookingsGroup, TabTypes } from '../../../../../types';
import { BookingsListDataHooks } from '../../datahooks';
import { Accordion, AccordionSize } from 'wix-ui-tpa/cssVars';
import { classes, st } from './BookingsList.st.css';
import { renderBookingDetails } from './BookingDetails';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useGroupHeaderGetters } from '../../../../../core/hooks/useGroupHeaderGetter';
import { groupLimit } from '../../../../../consts/limit';
import { LimitIndicator } from './LimitIndicator';

export const BookingsList: FC = () => {
  const { upcomingData, historyData, selectedTab, environmentModes } =
    useApplicationContext();
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const { getTitle, getSubtitle } = useGroupHeaderGetters();
  let initiallyExpanded = '';

  const data =
    selectedTab.type === TabTypes.UPCOMING ? upcomingData : historyData;

  const [dataToShow, setDataToShow] = useState<BookingsGroup[]>(
    data!.groups.slice(0, groupLimit),
  );

  useEffect(() => {
    setDataToShow(data!.groups.slice(0, groupLimit));
  }, [data?.groups.length]);

  if (environmentModes.isEditorMode) {
    initiallyExpanded = data?.groups?.[1]?.groupId || '';
  }

  return (
    <div className={classes.root} data-hook={BookingsListDataHooks.Main}>
      <Accordion
        className={st(classes.accordion, { isMobile })}
        data-hook={BookingsListDataHooks.Accordion}
        initiallyExpanded={initiallyExpanded}
        size={AccordionSize.Large}
      >
        {dataToShow.map((group, index) =>
          renderBookingDetails({
            group,
            t,
            isMobile,
            isRTL,
            selectedTab,
            title: getTitle(group),
            subtitle: getSubtitle(group),
            isManageButtonOpen:
              environmentModes.isEditorMode && index === 0 && !isMobile,
          }),
        )}
      </Accordion>
      {data!.groups.length > groupLimit && <LimitIndicator />}
    </div>
  );
};
