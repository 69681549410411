export const handleWithStopPropagation = (
  e: React.MouseEvent,
  cb?: Function,
) => {
  e.stopPropagation();

  if (cb && typeof cb === 'function') {
    cb(e);
  }
};
